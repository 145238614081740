import React, {Fragment, Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Col, Input, message, Tabs, Card, Switch, Button, Form} from 'antd'
import { CloseCircleOutlined,CheckCircleOutlined } from '@ant-design/icons';
import jwt_decode from "jwt-decode";

import AdminLayout from 'components/elements/AdminLayout'
import Scroller from 'components/elements/Scroller'
import SectionContent from 'components/elements/SectionContent'
import queryString, { parse } from 'query-string'

import {
  integrationConfigActions,
  administratorActions
} from 'actions'
// import { google } from 'googleapis';

const { Search } = Input
const pageTitle = 'Users'
const storeKey = 'accounts'
const createPath = '/admin/patients/create'
const editPath = '/admin/patients/edit'
const _ = require('lodash')
const { TabPane } = Tabs
class Integrations extends Component {
  constructor(props, context) {
    super(props, context)
    const parsed = queryString.parse(location.search);
    const optionalGoogle = {};
    if(parsed.code){      
      optionalGoogle.code = parsed.code
    }
    this.state = {
      googleCalendar: {
        active: _.get(props, "integrationConfigs['googleCalendar'].active", false),
        fetchActive: _.get(props, "integrationConfigs['googleCalendar'].fetchActive", false),
        transcribeActive: _.get(props, "integrationConfigs['googleCalendar'].transcribeActive", false),
        data: {
          ..._.get(props, "integrationConfigs['googleCalendar'].data", {}),
          ...optionalGoogle
        }
      },
      evet: {
        active: _.get(props, "integrationConfigs['evet'].active", false),
        fetchActive: _.get(props, "integrationConfigs['evet'].fetchActive", false),
        transcribeActive: _.get(props, "integrationConfigs['evet'].transcribeActive", false),
        data: _.get(props, "integrationConfigs['evet'].data", {})
      },
      vetter: {
        fetchActive: _.get(props, "integrationConfigs['vetter'].fetchActive", false),
        transcribeactive: _.get(props, "integrationConfigs['vetter'].transcribeActive", false),
        isPartner: _.get(props, "integrationConfigs['vetter'].isPartner", false),
        data: _.get(props, "integrationConfigs['vetter'].data", {})
      },
      gravity: {
        active: _.get(props, "integrationConfigs['gravity'].active", false),
        data: _.get(props, "integrationConfigs['gravity'].data", {})
      },
      covetrus: {
        active: _.get(props, "integrationConfigs['covetrus'].active", false),
        fetchActive: _.get(props, "integrationConfigs['covetrus'].fetchActive", false),
        transcribeActive: _.get(props, "integrationConfigs['covetrus'].transcribeActive", false),
        data: _.get(props, "integrationConfigs['covetrus'].data", {})
      },
      datapoint: {
        active: _.get(props, "integrationConfigs['datapoint'].active", false),
        fetchActive: _.get(props, "integrationConfigs['datapoint'].fetchActive", false),
        transcribeActive: _.get(props, "integrationConfigs['datapoint'].transcribeActive", false),
        data: _.get(props, "integrationConfigs['datapoint'].data", {})
      },
      rhapsody: {
        active: _.get(props, "integrationConfigs['rhapsody'].active", false),
        fetchActive: _.get(props, "integrationConfigs['rhapsody'].fetchActive", false),
        transcribeActive: _.get(props, "integrationConfigs['rhapsody'].transcribeActive", false),
        data: _.get(props, "integrationConfigs['rhapsody'].data", {})
      }
    }
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.handleToggleEvet = this.handleToggleEvet.bind(this)
    this.handleToggleEvetTranscribe = this.handleToggleEvetTranscribe.bind(this)
    this.handleToggleEvetFetch = this.handleToggleEvetFetch.bind(this)
    this.handleToggleEvetFollowUps = this.handleToggleEvetFollowUps.bind(this)
    this.handleToggleEvetRaw = this.handleToggleEvetRaw.bind(this)
    
    this.handleToggleRhapsodyTranscribe = this.handleToggleRhapsodyTranscribe.bind(this)
    this.handleToggleRhapsodyFetch = this.handleToggleRhapsodyFetch.bind(this)

    this.handleToggleVetterFetch = this.handleToggleVetterFetch.bind(this)
    this.handleToggleVetterTranscribe = this.handleToggleVetterTranscribe.bind(this)
    this.handleToggleVetterRaw = this.handleToggleVetterRaw.bind(this)
    
    this.handleToggleGravity = this.handleToggleGravity.bind(this)
    this.handleToggleGravityProduction = this.handleToggleGravityProduction.bind(this)
    
    this.handleToggleCovetrus = this.handleToggleCovetrus.bind(this)
    this.handleToggleCovetrusTranscribe = this.handleToggleCovetrusTranscribe.bind(this)
    this.handleToggleCovetrusFetch = this.handleToggleCovetrusFetch.bind(this)
    
    this.handleToggleDatapointTranscribe = this.handleToggleDatapointTranscribe.bind(this)
    this.handleToggleDatapointFetch = this.handleToggleDatapointFetch.bind(this)
    this.handleToggleDatapoint = this.handleToggleDatapoint.bind(this)


    this.handlePlaceholderTargetIDSubmit = this.handlePlaceholderTargetIDSubmit.bind(this)
    this.handleChangePlaceholderPatientID = this.handleChangePlaceholderPatientID.bind(this)
    this.handlePlaceholderTargetIDRemove = this.handlePlaceholderTargetIDRemove.bind(this)
    this.handleTranscribeBlock = this.handleTranscribeBlock.bind(this)

    this.handleToggleGoogleCalendar = this.handleToggleGoogleCalendar.bind(this)
    this.handleToggleGoogleCalendarTranscribe = this.handleToggleGoogleCalendarTranscribe.bind(this)
    this.handleToggleGoogleCalendarFetch = this.handleToggleGoogleCalendarFetch.bind(this)

    this.googleAuthorize = this.googleAuthorize.bind(this)
  }

  componentDidMount() {
    this.fetch()
    if(_.get(this, 'state.googleCalendar.data.code')){
      integrationConfigActions.googleAuth({
        code: _.get(this, 'state.googleCalendar.data.code')
      }).then((result)=>{
        window.location.href=`${window.location.origin}/admin/integrations`
      }).catch((error)=>{
        window.location.href=`${window.location.origin}/admin/integrations?googleAuthErrors=${error.message}`
      })
    }
  }

  fetch() {
    if(otterStore.getState().currentOrganization.id){
        integrationConfigActions.fetch()
    }else{
        setTimeout(this.fetch, 1000)
    }
  }

  onSubmit(integration, e){
    if(e){
      e.preventDefault()
    }

    integrationConfigActions.update({
      ...this.props.integrationConfigs[integration],
      integration,
      data: this.state[integration].data,
      active: true
    })
  }
  
  diff(previousProps, ...keys){
    let options = {};

    for(let key of keys){
      const previous = _.get(previousProps, key)
      const next = _.get(this.props, key) 
      
      if(previous != next){
        _.set(options, key, next)
      }
    }    
    return options;
  }

  get placeholderTargetPatientIDs() {
    return this.state.covetrus.data.placeholderTargetPatientIDs || []
  }
  
  
// clear text box on submission
// remove IDs from list with button press
  handlePlaceholderTargetIDSubmit(e) {
    if(e.keyCode == 13) {
      e.preventDefault()
      let placeholderTargetPatientIDs = this.placeholderTargetPatientIDs
      placeholderTargetPatientIDs.push(e.target.value)
      let integrationConfig = this.state.covetrus
      integrationConfig.data.placeholderTargetPatientIDs = placeholderTargetPatientIDs
      this.setState({
        covetrus: integrationConfig,
        placeholderPatientID: ''
      })
    }
  }

  handleChangePlaceholderPatientID(e) {
    this.setState({
      placeholderPatientID: e.target.value
    }) 
  }

  handlePlaceholderTargetIDRemove(id){
    let integrationConfig = this.state.covetrus
    integrationConfig.data.placeholderTargetPatientIDs = (
      integrationConfig.data.placeholderTargetPatientIDs.filter((_id)=>
        id !== _id
      )
    )
    this.setState({
      covetrus: integrationConfig
    })
  }

  componentDidUpdate(previousProps){
    
    const options = this.diff(
      previousProps,
      'integrationConfigs.googleCalendar.active',
      'integrationConfigs.googleCalendar.fetchActive',
      'integrationConfigs.googleCalendar.transcribeActive',
      'integrationConfigs.googleCalendar.data',
      'integrationConfigs.googleCalendar.data.accessToken',
      'integrationConfigs.googleCalendar.data.calendarID',

      'integrationConfigs.evet.active',
      'integrationConfigs.evet.fetchActive',
      'integrationConfigs.evet.transcribeActive',
      'integrationConfigs.evet.data',
      'integrationConfigs.evet.data.raw',
      
      'integrationConfigs.rhapsody.fetchActive',
      'integrationConfigs.rhapsody.transcribeActive',
      'integrationConfigs.rhapsody.data.id',
      'integrationConfigs.rhapsody.data.apiKey',

      'integrationConfigs.vetter.data.apiKey',
      'integrationConfigs.vetter.fetchActive',
      'integrationConfigs.vetter.transcribeActive',
      'integrationConfigs.vetter.isPartner',

      'integrationConfigs.gravity.data.oid',
      'integrationConfigs.gravity.data.authToken',
      'integrationConfigs.gravity.active',

      'integrationConfigs.covetrus.active',
      'integrationConfigs.covetrus.fetchActive',
      'integrationConfigs.covetrus.transcribeActive',
      'integrationConfigs.covetrus.data',
      'integrationConfigs.covetrus.data.siteID',

      'integrationConfigs.datapoint.active',
      'integrationConfigs.datapoint.fetchActive',
      'integrationConfigs.datapoint.transcribeActive',
      'integrationConfigs.datapoint.data',
      'integrationConfigs.datapoint.data.siteID'
    )

    const integrationConfigs = options.integrationConfigs || {}

    if(this.props.integrationConfigs != previousProps.integrationConfigs) {

      const data =  JSON.parse(
        JSON.stringify({
          googleCalendar: {
            ...this.state.googleCalendar || {},
            ...integrationConfigs.googleCalendar || {}
          },
          evet: {
            ...this.state.evet || {},
            ...integrationConfigs.evet || {}
          },
          rhapsody: {
            ...this.state.rhapsody || {},
            ...integrationConfigs.rhapsody || {}
          },
          vetter: {
            ...this.state.vetter || {},
            ...integrationConfigs.vetter || {}
          },
          gravity: {
            ...this.state.gravity || {},
            ...integrationConfigs.gravity || {}
          },
          covetrus: {
            ...this.state.covetrus || {},
            ...integrationConfigs.covetrus || {}
          },
          datapoint: {
            ...this.state.datapoint || {},
            ...integrationConfigs.datapoint || {}
          }
        })
      )

      this.setState(data)
    }
  }
  
  onChange(type, target, e, cb){
    const data  = (this.state[type] || {
      data:{}
    }).data || {}
    const integration = {...this.state[type]}
    data[target] = e.target.value
    integration.data = {...data}
    const callback = ()=>{
      if(cb){
        cb()
      }
    }
    this.setState({
      [`${type}`]: integration
    }, callback)
  }
  
  handleToggleEvet(active){
    let evetConfig = this.state.evet || {}
    evetConfig.active = active
    this.setState({
      evet: evetConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'evet',
        active: Boolean(active)
      })
    })
  }

  handleToggleGoogleCalendar(active){
    let googleCalendarConfig = this.state.googleCalendar || {}
    googleCalendarConfig.active = active
    
    this.setState({
      googleCalendar: googleCalendarConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'googleCalendar',
        active: Boolean(active)
      })
    })
  }

  handleToggleGoogleCalendarFetch(active){
    let googleCalendarConfig = this.state.googleCalendar || {}
    googleCalendarConfig.fetchActive = active

    this.setState({
      googleCalendar: googleCalendarConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'googleCalendar',
        fetchActive: Boolean(active)
      })
    })
  }

  handleToggleGoogleCalendarTranscribe(active){
    let googleCalendarConfig = this.state.googleCalendar || {}
    googleCalendarConfig.transrcibeActive = active
    
    this.setState({
      evet: googleCalendarConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'googleCalendar',
        transcribeActive: Boolean(active)
      })
    })
  }

  handleToggleEvetFetch(active){
    let evetConfig = this.state.evet || {}
    evetConfig.fetchActive = active

    this.setState({
      evet: evetConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'evet',
        fetchActive: Boolean(active)
      })
    })
  }

  handleToggleEvetTranscribe(active){
    let evetConfig = this.state.evet || {}
    evetConfig.transcribeActive = active
    
    this.setState({
      evet: evetConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'evet',
        transcribeActive: Boolean(active)
      })
    })
  }


  handleToggleRhapsodyFetch(active){
    let rhapsodyConfig = this.state.rhapsody || {}
    rhapsodyConfig.rhapsodyActive = active

    this.setState({
      rhapsody: rhapsodyConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'rhapsody',
        fetchActive: Boolean(active)
      })
    })
  }

  handleToggleRhapsodyTranscribe(active){
    let rhapsodyConfig = this.state.rhapsody || {}
    rhapsodyConfig.transcribeActive = active
    
    this.setState({
      evet: rhapsodyConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'rhapsody',
        transcribeActive: Boolean(active)
      })
    })
  }

  handleToggleEvetFollowUps(active){
    let evetConfig = this.state.evet || {}
    evetConfig.data = evetConfig.data || {}
    evetConfig.data.followUps = active
    
    this.setState({
      evet: evetConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'evet',
        data: evetConfig.data
      })
    })
  }

  handleToggleEvetRaw(active){
    let evetConfig = this.state.evet || {}
    evetConfig.data = evetConfig.data || {}
    evetConfig.data.raw = active
    
    this.setState({
        evet: evetConfig
    }, ()=>{
        console.log(evetConfig)
        integrationConfigActions.update({
            integration: 'evet',
            data: evetConfig.data
        })
    })
  }

  handleToggleVetterFetch(active){
    let vetterConfig = this.state.vetter || {}
    vetterConfig.fetchActive = active
    this.setState({
      vetter: vetterConfig
    }, ()=>{
      integrationConfigActions.update({
        ...this.props.integrationConfigs.vetter,
        integration: 'vetter',
        fetchActive: Boolean(active)
      })
    })
  }
  handleToggleVetterTranscribe(active){
    let vetterConfig = this.state.vetter || {}
    vetterConfig.transcribeActive = active
    this.setState({
      vetter: vetterConfig
    }, ()=>{
      integrationConfigActions.update({
        ...this.props.integrationConfigs.vetter,
        integration: 'vetter',
        transcribeActive: Boolean(active)
      })
    })
  }
  handleToggleVetterRaw(active){
    let vetterConfig = this.state.vetter || {}
    vetterConfig.data = vetterConfig.data || {}
    vetterConfig.data.raw = active
    
    this.setState({
        evet: vetterConfig
    }, ()=>{
        integrationConfigActions.update({
            integration: 'vetter',
            data: vetterConfig.data
        })
    })
  }

  handleToggleGravity(active){
    let gravityConfig = {...(this.state.gravity || {})}
    gravityConfig.active = active
    gravityConfig.data = gravityConfig.data || {}
    gravityConfig.data.staging = !!gravityConfig.data.staging

    this.setState({
      gravity: gravityConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'gravity',
        active: Boolean(active),
        data: gravityConfig.data
      })
    })
  }

  handleToggleGravityProduction(value){
    const gravity = {...this.state.gravity}
    gravity.data.staging = !value
    
    this.setState({
      gravity
    })
  }

  handleToggleCovetrus(active){
    let covetrusConfig = {...(this.state.covetrus || {})}
    covetrusConfig.active = active
    covetrusConfig.data = covetrusConfig.data || {}
    // covetrusConfig.data.staging = !!gravityConfig.data.staging

    this.setState({
      covetrus: covetrusConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'covetrus',
        active: Boolean(active),
        data: covetrusConfig.data
      })
    })
  }

  handleToggleCovetrusFetch(active){
    let covetrusConfig = this.state.covetrus || {}
    covetrusConfig.fetchActive = active

    this.setState({
      covetrus: covetrusConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'covetrus',
        fetchActive: Boolean(active)
      })
    })
  }

  handleToggleCovetrusTranscribe(active){
    let covetrusConfig = this.state.covetrus || {}
    covetrusConfig.transcribeActive = active
    this.setState({
      covetrus: covetrusConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'covetrus',
        transcribeActive: Boolean(active)
      })
    })
  }

  handleToggleDatapoint(active){
    let datapointConfig = {...(this.state.datapoint || {})}
    datapointConfig.active = active
    datapointConfig.data = datapointConfig.data || {}
    // datapointConfig.data.staging = !!gravityConfig.data.staging

    this.setState({
      datapoint: datapointConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'datapoint',
        active: Boolean(active),
        data: datapointConfig.data
      })
    })
  }

  handleToggleDatapointFetch(active){
    let datapointConfig = this.state.datapoint || {}
    datapointConfig.fetchActive = active

    this.setState({
      datapoint: datapointConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'datapoint',
        fetchActive: Boolean(active)
      })
    })
  }

  handleToggleDatapointTranscribe(active){
    let datapointConfig = this.state.datapoint || {}
    datapointConfig.transcribeActive = active
    this.setState({
      datapoint: datapointConfig
    }, ()=>{
      integrationConfigActions.update({
        integration: 'datapoint',
        transcribeActive: Boolean(active)
      })
    })
  }

  get showTranscriberToggle(){
    if(!_.get(this, 'state.evet.data.group')){
      return true
    }

    if(_.get(this, 'state.evet.data.group') && this.props.currentOrganization.parentID){
      return true
    }
    return false
  }
  handleTranscribeBlock(){
    this.setState({
      blockingStyling: {
        color: '#ff0000',
        transition: `color 0s`
      }
    }, ()=>{
      setTimeout(()=>{
        this.setState({
          blockingStyling: {
            color: '#000',
            transition: `color 8s`
          }
        })
      }, 3000)
    })
  }

  // get googleClient (){
  //   if(!this.__client){
  //     return this.__client = google.accounts.oauth2.initTokenClient({
  //       grant_type: 'offline',
  //       client_id: '1069695361848-dkmb2al3630p1spvv0inhebpkat8vtcj.apps.googleusercontent.com',
  //       scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events',
  //               // https://www.googleapis.com/auth/contacts.readonly',
  //       callback: (tokenResponse) => {
  //         console.log('duh token', tokenResponse)
  //         const newState = JSON.parse(JSON.stringify(this.state))
  //         newState.googleCalendar.data.accessToken = tokenResponse.access_token
  //         this.setState(newState, ()=>{
  //           console.log(this.state)
  //         })
  //       },
  //     });
  //   }else{
  //     return this.__client
  //   }
  // }
  googleAuthorize(){
    const scope = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events'
    const redirectURI = 'http://localhost:4001/admin/integrations/connect/google/callback'

    // window.location.href = [
    //   'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount',
    //   '?access_type=offline',
    //   `&redirect_uri=${redirectURI}`,
    //   `&scope=${scope}&flowName=GeneralOAuthFlow`,
    //   '&response_type=code&client_id=75934731937-19rcer69kuui1sqgdlqfjq387sribl42.apps.googleusercontent.com'
    // ].join('')

    if(window.location.href.includes('localhost')){
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.calendars%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&response_type=code&client_id=75934731937-cg6glto28ccuonhuv1ka882j869c3q16.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A4001%2Fadmin%2Fintegrations%2Fconnect%2Fgoogle%2Fcallback&prompt=consent`
    }else{
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.calendars%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&response_type=code&client_id=75934731937-cg6glto28ccuonhuv1ka882j869c3q16.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fscheduling.nextinline.io%2Fadmin%2Fintegrations%2Fconnect%2Fgoogle%2Fcallback&prompt=consent`
    }
  }

  render() {
    return (
      <div>
        <AdminLayout>
          <SectionContent noPadding={true} noMargin={true}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Google Calendar" key="1">
                <Scroller>
                    <Card bordered={false}>
                        {_.get(this, 'state.googleCalendar.data.accessToken') ? (
                            <a href="#" onClick={this.googleAuthorize}>
                                Re Authorize Google
                            </a>
                        ):(
                            <a href="#" onClick={this.googleAuthorize}>
                                Authorize Google
                            </a>
                        )}
                        <br />
                        <br />
                        <div style={{fontSize: '1.2em'}}>
                            Access Token {_.get(this, 'state.googleCalendar.data.accessToken') && (
                                <CheckCircleOutlined style={{color: '#22aa22'}}/>
                            )}
                        </div>
                    </Card>
                    <Card bordered={false}>
                        <Switch onChange={this.handleToggleGoogleCalendar} checked={_.get(this, 'state.googleCalendar.active')} />
                        &nbsp; 
                        <strong>
                        Activate
                        </strong>
                    </Card>
                    {_.get(this, 'state.googleCalendar.active') && (
                        <div>
                        <Card bordered={false}>
                            <Switch onChange={this.handleToggleGoogleCalendarFetch} checked={_.get(this, 'state.googleCalendar.fetchActive')} />
                            &nbsp; 
                            <strong>
                            Fetch
                            </strong>
                        </Card>
                            <div>
                            <Card bordered={false}>
                                <form onSubmit={this.onSubmit.bind(null, 'googleCalendar')}>
                                    Calendar ID:
                                    <Input 
                                        value={this.state.googleCalendar.data.calendarID}
                                        onChange={this.onChange.bind(null, 'googleCalendar', 'calendarID')}
                                    />
                                    
                                    <br />
                                    <br />
                                    <Button htmlType={'submit'}>
                                        Save
                                    </Button>
                                </form>
                                <Switch onChange={this.handleToggleGoogleCalendarTranscribe} checked={_.get(this, 'state.googleCalendar.transcribeActive')} />
                                &nbsp; 
                                <strong>
                                Transcribe
                                </strong>
                            </Card>
                            </div>
                        </div>
                    )}
                </Scroller>
            </TabPane>
            <TabPane tab="Evet" key="2">
                <Scroller>
                    <Card bordered={false}>
                    {_.get(this, 'state.evet.data.id') ? (
                        <div>
                            id: {_.get(this, 'state.evet.data.id')}
                            <br />
                            host: {_.get(this, 'state.evet.data.host')}
                        </div>
                        ):(
                        <div>
                            No Data from server sync
                        </div>
                        )}
                    </Card>
                    <Card bordered={false}>
                        <Switch onChange={this.handleToggleEvetFetch} checked={_.get(this, 'state.evet.fetchActive')} />
                        &nbsp; 
                        <strong>
                        Fetch
                        </strong>
                    </Card>
                    {this.showTranscriberToggle ?<div>
                        <Card bordered={false}>
                        <Switch onChange={this.handleToggleEvetTranscribe} checked={_.get(this, 'state.evet.transcribeActive')} />
                        &nbsp; 
                        <strong>
                            Transcribe
                        </strong>
                        </Card>
                    </div>
                    :(
                        <div>
                        <Card bordered={false}>
                        <Switch checked={false} onClick={this.handleTranscribeBlock}/>
                        &nbsp; 
                        <strong>
                            Transcribe
                        </strong>
                        <br />
                        <div 
                            style={this.state.blockingStyling}
                        >
                            <b>Important</b>
                            <br />
                            Transcribers cannot be turned on for eVet MultiOrgs until the parent organization is set
                            <br />
                            We suggest you use the following organization:
                            <br />
                            {this.state.evet.data.group.Name}
                        </div>
                        </Card>
                        </div>
                    )}
                    <Card bordered={false}>
                        <Switch onChange={this.handleToggleEvetFollowUps} checked={_.get(this, 'state.evet.data.followUps')} />
                        &nbsp; 
                        <strong>
                        Follow Ups (Evet Reminders)
                        </strong>
                    </Card>
                    <Card bordered={false}>
                        <Switch onChange={this.handleToggleEvetRaw} checked={_.get(this, 'state.evet.data.raw')} />
                        &nbsp; 
                        <strong>
                        Use Raw Data (Evet Raw)
                        </strong>
                    </Card>
                </Scroller>
            </TabPane>
            <TabPane tab="Rhapsody" key="3">
                <Scroller>
                    <Card bordered={false}>
                        <form onSubmit={this.onSubmit.bind(null, 'rhapsody')}>
                            <br />
                            <strong>Business ID</strong>
                            <Input 
                            value={this.state.rhapsody.data.id}
                            onChange={this.onChange.bind(null, 'rhapsody', 'id')}
                            />
                            <br />
                            <strong>ApiKey</strong>
                            <Input 
                            value={this.state.rhapsody.data.apiKey}
                            onChange={this.onChange.bind(null, 'rhapsody', 'apiKey')}
                            />
                            <Button htmlType={'submit'}>
                            Update
                            </Button>
                        </form>
                    </Card>
                    {this.state.rhapsody.data.apiKey && (
                        <Fragment>
                        <Card bordered={false}>
                            <Switch onChange={this.handleToggleRhapsodyFetch} checked={_.get(this, 'state.rhapsody.fetchActive')} />
                            &nbsp; 
                            <strong>
                            Fetch
                            </strong>
                        </Card>
                        <div>
                            <Card bordered={false}>
                            <Switch onChange={this.handleToggleRhapsodyTranscribe} checked={_.get(this, 'state.rhapsody.transcribeActive')} />
                            &nbsp; 
                            <strong>
                                Transcribe
                            </strong>
                            </Card>
                        </div>
                        </Fragment>
                    )}
                    {/* <Card bordered={false}>
                        <Switch onChange={this.handleToggleEvetFollowUps} checked={this.state.evet.data.followUps} />
                        &nbsp; 
                        <strong>
                        Follow Ups (Evet Reminders)
                        </strong>
                    </Card> */}
                </Scroller>
            </TabPane>
            <TabPane tab="Vetter" key="4">
                <Scroller>
                    <Card bordered={false}>
                        <form onSubmit={this.onSubmit.bind(null, 'vetter')}>
                        <Input.Group style={{width: '100%'}} compact>
                            <Input 
                            style={{width: 'calc(100% - 80px)'}}
                            value={this.state.vetter.data.apiKey}
                            onChange={this.onChange.bind(null, 'vetter', 'apiKey')}
                            />
                            <Button htmlType={'submit'}>
                            Update
                            </Button>
                        </Input.Group>
                        </form>
                        {this.state.vetter.data.apiKey && (
                        <Fragment>
                          {console.log(_.get(this, 'state.vetter.transcribeActive'))}
                            <Switch onChange={this.handleToggleVetterFetch} checked={_.get(this, 'state.vetter.fetchActive')} /> <strong>Fetch</strong>
                            <br />
                            <br />
                            <Switch onChange={this.handleToggleVetterTranscribe} checked={_.get(this, 'state.vetter.transcribeActive')} /> <strong>Transcribe</strong>
                            <br />
                            <br />
                            <Switch onChange={this.handleToggleVetterRaw} checked={_.get(this, 'state.vetter.raw')} /> <strong>Is Raw</strong>
                        </Fragment>
                        )}
                    </Card>
                </Scroller>
            </TabPane>
            <TabPane tab="Gravity" key="5">
                <Scroller>
                    <Card bordered={false}>
                        <Switch onChange={this.handleToggleGravity} checked={_.get(this, 'state.gravity.active')} />
                        &nbsp; 
                        <strong>
                        Gravity
                        </strong>
                        <br />
                        <br />
                        {this.state.gravity.active && (
                        <form onSubmit={this.onSubmit.bind(null, 'gravity')}>
                            OID:
                            <Input 
                                value={this.state.gravity.data.oid }
                                onChange={this.onChange.bind(null, 'gravity', 'oid')}
                                />
                            <br />
                            <br />
                            Auth Token:
                            <Input 
                                value={this.state.gravity.data.authToken}
                                onChange={this.onChange.bind(null, 'gravity', 'authToken')}
                            />
                            <br />
                            <br />
                            {administratorActions.test(_.get(this, 'props.session.account')) &&(
                                <Fragment>
                                <Switch onChange={this.handleToggleGravityProduction} checked={!_.get(this, 'state.gravity.data.staging')} /> Production
                                <br />
                                <br />
                                </Fragment>
                            )}
                            <Button htmlType={'submit'}>
                                Update
                            </Button>
                        </form>
                        )}
                    </Card>
                </Scroller>
            </TabPane>
            <TabPane tab="Covetrus" key="6">
                <Scroller>
                    <Card bordered={false}>
                        <form onSubmit={this.onSubmit.bind(null, 'covetrus')}>
                            Installation:
                            <Input 
                                value={this.state.covetrus.data.installation}
                                onChange={this.onChange.bind(null, 'covetrus', 'installation')}
                            />
                            <br />
                            <br />
                            Site ID:
                            <Input 
                                value={this.state.covetrus.data.siteID}
                                onChange={this.onChange.bind(null, 'covetrus', 'siteID')}
                            />
                            <br />
                            <br />
                            Placeholder Target Client ID:
                            <Input 
                                value={this.state.covetrus.data.placeholderTargetClientID}
                                onChange={this.onChange.bind(null, 'covetrus', 'placeholderTargetClientID')}
                            />
                            <br />
                            <br />
                            Placeholder Target Patient ID:
                            {this.placeholderTargetPatientIDs.map((placeholderTargetID, i) => (
                                <li key={`placeholderPatientID-${i}`}>
                                {placeholderTargetID} <CloseCircleOutlined onClick={this.handlePlaceholderTargetIDRemove.bind(null, placeholderTargetID)}/>
                                </li>
                            ))}
                            <br />
                            <Input
                                id='patientIdSubmit'
                                value={this.state.placeholderPatientID}
                                onKeyDown={this.handlePlaceholderTargetIDSubmit}
                                onChange={this.handleChangePlaceholderPatientID}
                                // onChange={this.onChange.bind(null, 'covetrus', 'placeholderTargetPatientIDs')}
                            />
                            <br />
                            <br />
                            PIMS:
                            <Input 
                                value={this.state.covetrus.data.pims}
                                onChange={this.onChange.bind(null, 'covetrus', 'pims')}
                            />
                            <br />
                            <br />

                            <Card bordered={false}>
                                <Switch onChange={this.handleToggleCovetrusFetch} checked={_.get(this, 'state.covetrus.fetchActive')} />
                                &nbsp; 
                                <strong>
                                Fetch
                                </strong>
                            </Card>
                            <Card bordered={false}>
                                <Switch onChange={this.handleToggleCovetrusTranscribe} checked={_.get(this, 'state.covetrus.transcribeActive')} />
                                &nbsp; 
                                <strong>
                                Transcribe
                                </strong>
                            </Card>
                            <Button htmlType={'submit'}>
                                Update
                            </Button>
                        </form>
                    </Card>
                </Scroller>
            </TabPane>            
            <TabPane tab="Data Point" key="7">
                <Scroller>
                    <Card bordered={false}>
                        <form onSubmit={this.onSubmit.bind(null, 'datapoint')}>
                            Site ID:
                            <Input 
                                value={_.get(this, 'state.datapoint.data.siteid')}
                                onChange={this.onChange.bind(null, 'datapoint', 'siteid')}
                            />
                            <br />
                            <br />
                            Placeholder Target Client ID:
                            <Input 
                                value={_.get(this, 'state.datapoint.data.placeholderTargetClientID')}
                                onChange={this.onChange.bind(null, 'datapoint', 'placeholderTargetClientID')}
                            />
                            <br />
                            <br />
                            Placeholder Target Patient ID:
                            <br />
                            <Input
                                id='patientIdSubmit'
                                value={_.get(this, 'state.datapoint.data.placeholderTargetPatientID')}
                                onChange={this.onChange.bind(null, 'datapoint', 'placeholderTargetPatientID')}
                            />
        
                            <Card bordered={false}>
                                <Switch onChange={this.handleToggleDatapointFetch} checked={_.get(this, 'state.datapoint.fetchActive')} />
                                &nbsp; 
                                <strong>
                                Fetch
                                </strong>
                            </Card>
                            <Card bordered={false}>
                                <Switch onChange={this.handleToggleDatapointTranscribe} checked={_.get(this, 'state.datapoint.transcribeActive')} />
                                &nbsp; 
                                <strong>
                                Transcribe
                                </strong>
                            </Card>

                            <br />
                            <br />
                            <Button htmlType={'submit'}>
                                Update
                            </Button>
                        </form>
                    </Card>
                </Scroller>
            </TabPane>
          </Tabs>
          </SectionContent>
        </AdminLayout>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    integrationConfigs: state.integrationConfigs || {},
    currentOrganization: state.currentOrganization,
    session: state.session
  }
}

export default withRouter(connect(mapStateToProps)(Integrations))
